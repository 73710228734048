import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EntryPreview from "../components/entrypreview"

const Photographer = ({ data }) => (
  <Layout>
    <SEO title="Photographer" />
    <div className="collection">
      <div className="scroll-container">
        <div className="cards-container">
          {data.allAirtable.edges.map(({ node }) => {
            if (
              node.data.date != null &&
              node.data.photographer != null &&
              node.data.location != null
            ) {
              return (
                <EntryPreview
                  key={node.id}
                  img={
                    node.data.images
                      ? node.data.images.localFiles[0].childImageSharp.fixed
                      : ""
                  }
                  date={node.data.date}
                  photographer={node.data.photographer}
                  location={node.data.location[0].data.locationName}
                  entryId={node.id}
                />
              )
            } else {
              return <div></div>
            }
          })}
        </div>
      </div>
    </div>
  </Layout>
)

// Filter id of photographer somehow here
export const query = graphql`
  query photographerQuery($slug: String) {
    allAirtable(
      filter: { data: { photographer: { elemMatch: { id: { eq: $slug } } } } }
      sort: { fields: [data___date] }
    ) {
      edges {
        node {
          id
          data {
            date(formatString: "DD.MM.YYYY")
            location {
              data {
                locationName
              }
            }
            photographer {
              id
              data {
                photographerName
              }
            }
            images {
              localFiles {
                childImageSharp {
                  fixed(width: 350) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Photographer
